import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,);

function startLoader() {
  let counterElement = document.querySelector(".counter");
  let currentValue = 0;

  function updateCounter(){
    if (currentValue === 100) {
      return;
    }

    currentValue += Math.floor(Math.random() * 10) + 1;

    if (currentValue > 100){
      currentValue = 100;
    }

    counterElement.textContent = currentValue;

    let delay = Math.floor(Math.random() * 200) + 50;
    setTimeout(updateCounter, delay);

  }

  updateCounter();
}

startLoader();

gsap.from(".gooddrugs", 1.25,{
  delay:0.5,
  opacity:0,
  y:100,
});


gsap.to(".counter", 0.25, {
  delay:3.5,
  opacity:0,

});

gsap.to(".gooddrugs", .25,{
  delay:3.7,
  opacity:0,
  y:-100,
});

gsap.to(".bar", 1.5, {
  delay:3.5,
  height:0,
  z:-14000,
  stagger:{
    amount:0.5,
  },
  ease: "power4.inOut",
});

gsap.from("#hero", 1.5, {
  delay:4,
  y:700,
  stagger:{
    amount:0.5,
  },
  ease:"power4.inOut",
});

// // create the smooth scroller FIRST!
// let smoother = ScrollSmoother.create({
//   smooth: 2,   // seconds it takes to "catch up" to native scroll position
//   effects: true // look for data-speed and data-lag attributes on elements and animate accordingly
// });

let header = document.querySelector("header");
let nav = document.querySelector(".navbar");
let hero = document.querySelector("#hero");

// gsap.from([hero], {opacity:0, duration:2});


// const tl1 = gsap.timeline();

// tl1.to( "nav", { y:-10, opacity:0, duration:1, stagger:0.5 });
// tl1.to(".hero-img", {scale:1.5, opacity:0, duration:2});
// tl1.from(".red-apple",{scale:2.5, y:1000,duration:2},"-=1");
// tl1.to(".red-apple", {scale:1, y:-300, opacity:0,duration:1});
// tl1.from(".happypill", {scale:1, y:300, opacity:0,duration:1}, "-=0.5");


// ScrollTrigger.create({
// 	animation: tl1,
// 	trigger:"#hero",
// 	start:"top 0%",
//   end:"bottom +=100",
// 	scrub:2.25,
// 	pin:true,
// 	markers:true,
// });

const tl1 = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    pin:true,
    pinSpacing:false,
    stagger:1.6,
    trigger:"#hero",
    start:"50% 50%",
    end:"bottom +=100",
  },
});
tl1.to( [header,nav], 0.25, { y:-10, opacity:0, duration:1, stagger:0.5 });
tl1.to("video", {opacity:0},"+=-1");
tl1.from(".happypill", {scale:1, y:300, opacity:0,duration:1}, "-=0.5");


const details = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    pin:true,
    trigger:"#details",
    start:"top top",
    end:"bottom +=100",
  },
});


details.from(".details-bg", {opacity:0,scale:1.5, duration:0.5});
details.from(".details-container", {opacity:0,y:100,duration:0.5});


// const tl = gsap.timeline();

function sequence_animation() {
  const canvas = document.querySelector('#home>canvas');
  const context = canvas.getContext('2d');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  window.addEventListener('resize', function () {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    render();
  });

  function files(index) {
    var data = `
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-000.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-001.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-002.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-003.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-004.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-005.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-006.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-007.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-008.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-009.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-010.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-011.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-012.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-013.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-014.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-015.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-016.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-017.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-018.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-019.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-020.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-021.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-022.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-023.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-024.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-025.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-026.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-027.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-028.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-029.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-030.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-031.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-032.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-033.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-034.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-035.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-036.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-037.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-038.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-039.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-040.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-041.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-042.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-043.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-044.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-045.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-046.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-047.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-048.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-049.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-050.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-051.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-052.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-053.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-054.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-055.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-056.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-057.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-058.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-059.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-060.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-061.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-062.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-063.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-064.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-065.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-066.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-067.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-068.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-069.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-070.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-071.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-072.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-073.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-074.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-075.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-076.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-077.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-078.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-079.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-080.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-081.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-082.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-083.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-084.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-085.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-086.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-087.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-088.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-089.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-090.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-091.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-092.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-093.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-094.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-095.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-096.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-097.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-098.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-099.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-100.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-101.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-102.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-103.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-104.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-105.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-106.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-107.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-108.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-109.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-110.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-111.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-112.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-113.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-114.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-115.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-116.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-117.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-118.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-119.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-120.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-121.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-122.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-123.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-124.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-125.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-126.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-127.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-128.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-129.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-130.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-131.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-132.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-133.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-134.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-135.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-136.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-137.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-138.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-139.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-140.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-141.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-142.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-143.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-144.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-145.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-146.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-147.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-148.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-149.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-150.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-151.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-152.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-153.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-154.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-155.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-156.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-157.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-158.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-159.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-160.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-161.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-162.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-163.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-164.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-165.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-166.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-167.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-168.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-169.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-170.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-171.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-172.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-173.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-174.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-175.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-176.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-177.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-178.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-179.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-180.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-181.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-182.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-183.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-184.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-185.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-186.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-187.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-188.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-189.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-190.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-191.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-192.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-193.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-194.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-195.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-196.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-197.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-198.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-199.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-200.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-201.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-202.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-203.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-204.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-205.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-206.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-207.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-208.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-209.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-210.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-211.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-212.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-213.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-214.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-215.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-216.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-217.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-218.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-219.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-220.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-221.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-222.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-223.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-224.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-225.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-226.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-227.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-228.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-229.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-230.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-231.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-232.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-233.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-234.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-235.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-236.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-237.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-238.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-239.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-240.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-241.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-242.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-243.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-244.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-245.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-246.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-247.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-248.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-249.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-250.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-251.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-252.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-253.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-254.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-255.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-256.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-257.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-258.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-259.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-260.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-261.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-262.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-263.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-264.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-265.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-266.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-267.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-268.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-269.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-270.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-271.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-272.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-273.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-274.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-275.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-276.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-277.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-278.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-279.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-280.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-281.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-282.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-283.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-284.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-285.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-286.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-287.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-288.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-289.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-290.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-291.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-292.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-293.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-294.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-295.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-296.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-297.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-298.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-299.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-300.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-301.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-302.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-303.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-304.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-305.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-306.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-307.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-308.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-309.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-310.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-311.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-312.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-313.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-314.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-315.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-316.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-317.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-318.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-319.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-320.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-321.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-322.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-323.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-324.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-325.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-326.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-327.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-328.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-329.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-330.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-331.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-332.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-333.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-334.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-335.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-336.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-337.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-338.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-339.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-340.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-341.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-342.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-343.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-344.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-345.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-346.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-347.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-348.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-349.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-350.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-351.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-352.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-353.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-354.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-355.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-356.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-357.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-358.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-359.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-360.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-361.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-362.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-363.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-364.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-365.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-366.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-367.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-368.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-369.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-370.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-371.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-372.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-373.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-374.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-375.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-376.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-377.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-378.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-379.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-380.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-381.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-382.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-383.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-384.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-385.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-386.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-387.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-388.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-389.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-390.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-391.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-392.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-393.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-394.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-395.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-396.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-397.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-398.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-399.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-400.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-401.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-402.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-403.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-404.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-405.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-406.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-407.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-408.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-409.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-410.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-411.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-412.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-413.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-414.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-415.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-416.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-417.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-418.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-419.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-420.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-421.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-422.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-423.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-424.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-425.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-426.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-427.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-428.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-429.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-430.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-431.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-432.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-433.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-434.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-435.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-436.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-437.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-438.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-439.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-440.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-441.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-442.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-443.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-444.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-445.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-446.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-447.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-448.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-449.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-450.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-451.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-452.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-453.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-454.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-455.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-456.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-457.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-458.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-459.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-460.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-461.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-462.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-463.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-464.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-465.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-466.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-467.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-468.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-469.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-470.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-471.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-472.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-473.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-474.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-475.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-476.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-477.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-478.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-479.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-480.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-481.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-482.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-483.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-484.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-485.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-486.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-487.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-488.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-489.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-490.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-491.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-492.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-493.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-494.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-495.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-496.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-497.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-498.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-499.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-500.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-501.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-502.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-503.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-504.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-505.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-506.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-507.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-508.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-509.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-510.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-511.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-512.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-513.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-514.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-515.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-516.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-517.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-518.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-519.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-520.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-521.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-522.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-523.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-524.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-525.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-526.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-527.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-528.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-529.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-530.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-531.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-532.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-533.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-534.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-535.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-536.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-537.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-538.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-539.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-540.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-541.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-542.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-543.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-544.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-545.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-546.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-547.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-548.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-549.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-550.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-551.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-552.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-553.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-554.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-555.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-556.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-557.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-558.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-559.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-560.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-561.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-562.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-563.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-564.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-565.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-566.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-567.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-568.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-569.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-570.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-571.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-572.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-573.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-574.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-575.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-576.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-577.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-578.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-579.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-580.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-581.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-582.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-583.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-584.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-585.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-586.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-587.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-588.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-589.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-590.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-591.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-592.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-593.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-594.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-595.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-596.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-597.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-598.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-599.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-600.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-601.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-602.png
https://capsulesequence.s3.us-west-2.amazonaws.com/happyseq/capsulemain-603.png
 `
 return data.split('\n')[index];
  }

  const frameCount = 605;

  const images = [];
  const imageSeq = {
    frame: 0,
  };

  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = files(i);
    images.push(img);
  }

gsap.to(imageSeq,{
    frame: frameCount - 1,
    snap: 'frame',
    ease: 'none',
    scrollTrigger: {
      scrub: 1.8,
      pin: true,
      // pinSpacing:false,
      trigger: '#home',
      start:"top top",
      end:"bottom +=100px",
    },
    onUpdate: render,
  }

  ); 


  images[1].onload = render;

  function render() {
    scaleImage(images[imageSeq.frame], context);
  }
  
  function scaleImage(img, ctx) {
    var canvas = ctx.canvas;
    var hRatio = canvas.width / img.width;
    var vRatio = canvas.height / img.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvas.width - img.width * ratio) / 3;
    var centerShift_y = (canvas.height - img.height * ratio) / 3;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    ); 
  }



let tl4 = gsap.timeline();
tl4.from("canvas", {opacity:0,})
tl4.from("#product-info__description1", {x:-40, opacity:0, duration:3})
tl4.to("#product-info__description1", {opacity:0, duration:1})
tl4.from("#product-info__description2", {x:40, opacity:0, duration:1,delay:0.25});
tl4.to("#product-info__description1", {opacity:0, duration:2});
tl4.to("#product-info__description2", {opacity:0, duration:2})
tl4.from("#product-info__description3",3, {x:-40, opacity:0, duration:3});
tl4.to("#product-info__description3", {opacity:0, duration:2});
tl4.from("#product-info__description4", {x:40, opacity:0, duration:2});
tl4.to("#product-info__description4", {opacity:0, duration:2});
tl4.to("canvas", {opacity:0,duration:1});

ScrollTrigger.create({
  animation: tl4,
  trigger:"#home",
  start:"top top",
  end:"bottom top",
  stagger:0.5,
  scrub:2,
});


}

sequence_animation();


  // gsap.fromTo('#product-info__description1',{opacity:0}, {
  // opacity:1,
  // x:40,
  // stagger:1,
  // duration:0.5,
  // scrollTrigger:{
  //   scrub:0.5,
  //   trigger:'#home',
  //   start:'top top',
  //   end:'+=300px'
  // }
  // });

  //  gsap.fromTo('#product-info__description2',{opacity:0}, {
  // opacity:1,
  // x:40,
  // stagger:1,
  // duration:0.5,
  // scrollTrigger:{
  //   scrub:0.5,
  //   trigger:'#home',
  //   start:'center top',
  //   end:'+=800px'
  // }
  // });

// Detect if a link's href goes to the current page
function getSamePageAnchor (link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if(elem) {
    if(e) e.preventDefault();
    gsap.to(window, {scrollTo: elem});
  }
}

// If a link's href is within the current page, scroll to it instead
document.querySelectorAll('a[href]').forEach(a => {
  a.addEventListener('click', e => {
    scrollToHash(getSamePageAnchor(a), e);
  });
});

// Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);

const flavors = gsap.timeline({
  scrollTrigger:{
    scrub:3,
    stagger:2,
    pin:true,
    trigger:"#flavors",
    start:"top top",
    end:"+=3000",
  },
});


flavors.to(".strain-good", {opacity:0,scale:2,duration:0.5});

flavors.fromTo(".one", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".blue-0", {x:10,opacity:0})
flavors.from(".blue-1", { x:-10, opacity:0});
flavors.to(".flavor-one", { y:-100, opacity:0,duration:1});

flavors.fromTo(".two", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".pink-0", {x:10,opacity:0})
flavors.from(".pink-1", { x:-10, opacity:0});
flavors.to(".flavor-two", { y:100, opacity:0,duration:1});

flavors.fromTo(".three", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".peach-0", {x:10,opacity:0})
flavors.from(".peach-1", { x:-10, opacity:0});
flavors.to(".flavor-three", { y:100, opacity:0,duration:1});

flavors.fromTo(".four", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".watermelon-0", {x:10,opacity:0})
flavors.from(".watermelon-1", { x:-10, opacity:0});
flavors.to(".flavor-four", { y:100, opacity:0,duration:1});

flavors.fromTo(".five", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".pineapple-0", {x:10,opacity:0})
flavors.from(".pineapple-1", { x:-10, opacity:0});
flavors.to(".flavor-five", { y:100, opacity:0,duration:1});

flavors.fromTo(".six", { y:-100,opacity:0}, { y:0, opacity:1});
flavors.from(".red-0", {x:10,opacity:0})
flavors.from(".red-1", { x:-10, opacity:0});
flavors.to(".flavor-six", { y:100, opacity:0,duration:1});




    